import React, { useState, useEffect } from "react";
import { Drawer, withWidth } from "@material-ui/core";
import api from "../services/api";
import MyPage from "../components/MyPage";
import Sidebar from "../components/Sidebar";
import { withRouter } from "react-router-dom";
import withUser from "../HOCs/withUser";
import InfoCard from "../components/Shared/InfoCard";
import { compose } from "redux";

import { useUser } from "../context/UserProvider";
import Modal from "../components/Shared/Modal";
import MenuIcon from "@material-ui/icons/Menu";
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";

import GetAppIcon from "@material-ui/icons/GetApp";
import { CSVLink } from "react-csv";
import AnswersData from "../components/AnswersData";

function DashboardPage(props) {
  const { show, width } = props;
  const [users, setUsers] = useState([]);
  const [averages, setAverages] = useState(null);
  const [userInfoForModal, setUserInfoForModal] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(true);
  const [dataCsv, setDataCsv] = useState(null);
  const { user } = useUser();

const convertRatioToNumber = (ratio, total) => {
  console.log(ratio, total)
  return Math.round(ratio / 100 * total);
}

  useEffect(() => {
    const fetchDashboardData = async () => {
      const [res, res2, res3] = await Promise.all([
        api.get(`kpis`),
        api.get("users"),
        api.get("user-goals"),
      ]);
      if (res) {
        setUsers([
          ...res2.map(el => {
            return {
              ...el,
              goal:res3.filter(g => g.user.id === el.id)
            }
          })
        ]);
        setAverages({
          // dataTotal: res.dataTotal,
          dataSecondWeek: {...res.dataSecondWeek, userChangeModeRatio: convertRatioToNumber(res.dataSecondWeek.userChangeModeRatio, res.dataSecondWeek.allAnswers?.length)},
          dataAfterSecondWeek: {...res.dataAfterSecondWeek, userChangeModeRatio: convertRatioToNumber(res.dataAfterSecondWeek.userChangeModeRatio, res.dataAfterSecondWeek.allAnswers?.length)},
          dataFirstWeek: {...res.dataFirstWeek, userChangeModeRatio: convertRatioToNumber(res.dataFirstWeek.userChangeModeRatio, res.dataFirstWeek.allAnswers?.length)},
        });
         setDataCsv(
          ["budget", "duration", "distance", "co2", "userChangeModeRatio"].map(
            (kpi) => {
              return {
                type:
                  kpi === "userChangeModeRatio"
                    ? "percentage_user_have_change_mode"
                    : [kpi],
                less_equal_first_week: res.dataFirstWeek[kpi],
                bt_one_two_weeks: res.dataSecondWeek[kpi],
                more_two_weeks: res.dataAfterSecondWeek[kpi],
              };
            }
          )
          // less_equal_first_week: res.dataFirstWeek,
          // bt_one_two_weeks: res.dataSecondWeek,
          // more_two_weeks: res.dataAfterSecondWeek,
        );
      } else {
        console.log("Couldn't get dashboard data");
        setDataCsv(null);
      }
    };
    fetchDashboardData();
  }, [user]);

  const toggleDrawerMobile = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setOpenDrawer(open);
  };

  const definePageTitle = () => {
    let title;
    switch (show) {
      case "DASHBOARD":
        title = "Dashboard";
        break;
      case "USERS":
        title = "Données utilisateurs";
        break;
      case "PAGE":
        title = "Gérer ma page";
        break;
      case "ANSWERS":
        title = "Réponses aux questionnaires";
        break;
      default:
        break;
    }
    return title;
  };

  useEffect(() => {
    if (width !== "xs" && !openDrawer) {
      setOpenDrawer(true);
    }
  }, [width, openDrawer, setOpenDrawer]);

  if (!averages) return <div />;

  console.log(averages)

  return (
    <div id="dashboard-container">
      {/* <Sidebar {...props} openDrawer={openDrawer} /> */}

      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
      {width !== "xs" ? (
        <Drawer
          {...props}
          id="drawer-dashboard"
          openDrawer={openDrawer}
          variant="permanent"
          anchor={"left"}
          open={openDrawer}
          onClose={() => setOpenDrawer(!openDrawer)}
          // classes={{
          //   paper: classes.drawerPaper,
          // }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          <Sidebar />{" "}
        </Drawer>
      ) : (
        <SwipeableDrawer
          id="drawer-dashboard"
          // className={classes.drawer}
          open={openDrawer}
          role="presentation"
          onClick={toggleDrawerMobile(false)}
          onKeyDown={toggleDrawerMobile(false)}
        >
          <Sidebar />
        </SwipeableDrawer>
      )}

      <div
        style={{
          position: "absolute",
          left: "15px",
          top: "15px",
          height: "30px",
          width: "30px",
        }}
        onClick={toggleDrawerMobile(true)}
      >
        {" "}
        <MenuIcon />
      </div>

      <main className="dashboard-main">
        <h1>{definePageTitle()}</h1>
        {show === "DASHBOARD" ? (
          <div className="dashboard-wrap">
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <InfoCard
                title={"Nombre d'utilisateurs inscrits"}
                value={averages}
                type={"users"}
              />
              <InfoCard
                title={"Temps annuel moyen passé en voiture"}
                value={averages}
                type={"duration"}
                units={"hh:mm"}
              />
              <InfoCard
                title={"Distance annuelle moyenne "}
                value={averages}
                type={"distance"}
                units={"km"}
              />
              <InfoCard
                title={"Budget annuel moyen"}
                value={averages}
                type={"budget"}
                units={"€"}
              />
              <InfoCard
                title={"Emissions annuelles moyennes"}
                value={averages}
                type={"co2"}
                units={"kg de CO2"}
              />
              {/* <InfoCard
                title={"Utilisation de la voiture"}
                value={averages}
                type={'byCarRatio'}
              /> */}
              <InfoCard
                title={"Nombre d'utilisateurs ayant changé leurs habitudes"}
                value={averages}
                type={"userChangeModeRatio"}
                units={""}
              />
            </div>
            <div style={{ marginTop: "20px" }}>
              {dataCsv ? (
                <CSVLink
                  data={dataCsv}
                  className="button_main"
                  filename={`dashboard-${new Date().toISOString()}.csv`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none",
                  }}
                  target="_blank"
                  // onClick={() => handleDownloadCsv()}
                >
                  Télécharger en CSV
                  <GetAppIcon className="icon" />
                </CSVLink>
              ) : (
                <div
                  className="button_disabled"
                  style={{ display: "flex", alignItems: "center" }}
                  // onClick={() => handleDownloadCsv()}
                >
                  csv <GetAppIcon className="icon" />
                </div>
              )}
            </div>
          </div>
        ) : null}
        {show === "PAGE" ? (
          <MyPage pageData={{ page: "Test", description: "testestest" }} />
        ) : null}
        {show === "ANSWERS" ? <AnswersData users={users} /> : null}
      </main>
      {Boolean(userInfoForModal) ? (
        <Modal
          userInfoForModal={userInfoForModal}
          closeModal={() => setUserInfoForModal(null)}
        />
      ) : null}
    </div>
  );
}

export default compose(withUser, withRouter, withWidth())(DashboardPage);
